import componentsHandlers, { addEventsSelect } from "../componentsHandlers.js";
$(document).ready(function() {
  Object.assign(window,componentsHandlers)
  addEventsSelect()
  $("#nav-main").find(".nav__li").each(function(){
    if($(this).find("a").attr("href") === window.location.pathname){
      $(this).addClass("active")
    }else{
      $(this).removeClass("active")
    }
  })
  $("#modalMainClose").on("click",(e)=>{
    e.preventDefault()
    $("#modalMain").addClass("display-none")
  })
})